import React from "react";

//Importacion de Componentes
import { MainAboutUs } from "../components/AboutUsMain";
//Fin de Importacion de Componentes

//Importacion de Imagenes
import Img2 from "../img/2.png";
import Img12 from "../img/12.JPG";

export function AboutUs() {
  return (
    <div className="d-flex flex-column align-items-center mx-5 py-5 ">
        <img src={Img2} alt="Imagen principal de web tek innovations" className="img-fluid w-100 mb-4" />
        <MainAboutUs></MainAboutUs>
        <img src={Img12} alt="Web Tek Innovations Team" className="img-fluid w-100 mb-4" />
    </div>
    
  );
}