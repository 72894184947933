import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaBookOpen, FaHandsHelping } from 'react-icons/fa';

//importacion de imagenes
import AscensoAcabo from '../img/ascenso-a-cabo.png';
import PalmaCompasiva from '../img/PalmaCompasiva.JPG';

export function ProjectsMain() {
    return (
        <Container className="my-5">
            <h1 className="text-center mb-4">Our Projects</h1>            
            <Row className="mb-4">
                <Col md={4}>
                    <Card className="h-100">
                        <Card.Img variant="top" src={AscensoAcabo} />
                        <Card.Body>
                            <Card.Title><FaBookOpen className="me-2"/>Ascenso a Cabo</Card.Title>
                            <Card.Text>
                                An e-learning platform that allows students to prepare for the military promotion exams.
                            </Card.Text>
                            <Button variant="dark" href="#">View Website</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="h-100">
                        <Card.Img variant="top" src="path_to_project2_image.jpg" />
                        <Card.Body>
                            <Card.Title><FaBookOpen className="me-2"/>Tu permanencia . Working on it</Card.Title>
                            <Card.Text>
                                An e-learning platform that allows students to prepare for the military permanence exams.
                            </Card.Text>
                            <Button variant="dark" href="#" disabled>View Website</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="h-100">
                        <Card.Img variant="top" src={PalmaCompasiva} />
                        <Card.Body>
                            <Card.Title><FaHandsHelping className="me-2"/>Palma Compasiva</Card.Title>
                            <Card.Text>
                            Is a non-profit organization that helps people with their lives, providing professionals to those who need them.
                            </Card.Text>
                            <Button variant="dark" href="#">View Website</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
