import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

export function AboutUs() {
  const { t } = useTranslation();

  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">{t("About Us")}</h1>

      <p>
        <strong>Web Tek Innovations</strong>, {t("founded in 2023 by")}
        <strong>D. Valcarce</strong>
        {t(", was conceived with the dual mission offering top-tier software services to businesses and pioneering our own innovative software solutions. CEO & Founder vision was clear - to establish a brand synonymous with quality, innovation, and unparalleled service in the web software realm.")}
      </p>

      <p>
        {t("Our values stand firm on pillars of")}
        <strong>{t("integrity, innovation, excellence, and collaboration")}</strong>.
        {("Though new in our journey, we're steadfast in our resolve to set industry benchmarks and cater to businesses, big or small, with our tailored web solutions.")}
      </p>

      <p>
        {t("At Web Tek Innovations, we believe in transforming challenges into opportunities, always aiming for the zenith of excellence.")}
      </p>
    </Container>
  );
}

export default AboutUs;
