import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

//Importacion de React-Bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../img/logo/logo-black-no-bg35.png";
import Titulo from "../img/titulo/black-title-no-bg.png";
//Fin de Importacion de React-Bootstrap

//Importacion de Vistas
import { Main } from "../views/Main";
import { AboutUs } from "../views/AboutUs";
import { Projects } from "../views/Projects";
import { Services } from "../views/Services";
import { ContactUs } from "../views/ContactUs";
//Fin de Importacion de Vistas

export function Header() {
  const { t } = useTranslation("translation");

  return (
    <>
      <Router>
        <Navbar expand={false} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand>
            <img
                    src={Titulo}
                    alt="Web Tek Innovations"
                    class="img-fluid full-height"
                  />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel">
                <img src={Logo} alt="Web Tek Innovations" id="logo" class="img-fluid full-height"/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link className="nav-link" to="/">Home</Link>
                  <Link className="nav-link" to="/About">{t("About Us")}</Link>
                  <Link className="nav-link" to="/Projects">{t("Projects")}</Link>
                  <Link className="nav-link" to="/Services">{t("Services")}</Link>
                  <Link className="nav-link text-white bg-dark rounded text-center" to="/Contact">{t("Contact Us")}</Link>
                  {/*<NavDropdown title="Languages" id="offcanvasNavbarDropdown">
                    <NavDropdown.Item onClick={() => i18n.changeLanguage("en")}>
                      {t("English")}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => i18n.changeLanguage("es")}>
                      {t("Spanish")}
                    </NavDropdown.Item>
                  </NavDropdown>*/}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/About" element={<AboutUs />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Contact" element={<ContactUs />} />
          </Routes>
        </Navbar>
      </Router>
    </>
  );
}