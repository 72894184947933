import { Container, Row, Col } from "react-bootstrap";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaServer,
  FaWrench,
  FaLightbulb,
  FaBrain,
  FaCloud,
} from "react-icons/fa";

import Img3 from "../img/3.png";
import Img13 from "../img/14.JPG";


export function Services() {
  return (
    <Container className="d-flex justify-content-center my-5">
      <Row>
        <img src={Img3} alt="Web Tek Innovations Personal Trabajando" />
      </Row>
      <Row className="my-5 py-5 text-center">
        <h1 className="mb-4">OUR SERVICES</h1>
        <p className="mb-4">
          At Web Tek Innovations, we're not just developers—we're innovators.
          Dive deeper into each of our offerings below:
        </p>
      </Row>

      {/* Custom Web Development */}
      <Row className="mb-5 service-detail">
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaLaptopCode size={50} />
          <h3>Custom Web Development</h3>
        </Col>
        <Col md={6}>
          <p>
            Our expert team crafts responsive websites tailored to your unique
            needs. With clean code and modern design, our sites excel in both
            form and function.
          </p>
          <p>
            Features:
            <ul>
              <li>E-commerce integrations (Shopify, Magento)</li>
              <li>Dynamic content with popular CMS (WordPress, Drupal)</li>
              <li>Interactive elements & animations</li>
            </ul>
          </p>
        </Col>
      </Row>

      {/* Progressive Web Apps (PWA) */}
      <Row className="mb-5 service-detail">
        <Col md={6}>
          <p>
            Leverage the power of mobile-first designs. Our PWAs are quick,
            reliable, and offer an app-like experience directly in the browser.
          </p>
          <p>
            Benefits:
            <ul>
              <li>Consistent performance across devices</li>
              <li>Offline capabilities</li>
              <li>Enhanced user engagement</li>
            </ul>
          </p>
        </Col>
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaMobileAlt size={50} className="mb-3" />
          <h3>Progressive Web Apps (PWA)</h3>
        </Col>
      </Row>

      {/* Backend Development */}
      <Row className="mb-5 service-detail">
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaServer size={50} className="mb-3" />
          <h3>Backend Development</h3>
        </Col>
        <Col md={6}>
          <p>
            At the heart of every dynamic web application lies robust backend
            infrastructure. Web Tek Innovations is committed to building
            scalable, efficient, and secure backend systems that serve as the
            foundation of your digital projects.
          </p>
          <p>
            Our Services:
            <ul>
              <li>
                <strong>API Development:</strong> Designing and developing
                powerful APIs to interact with frontend systems and third-party
                services.
              </li>
              <li>
                <strong>Database Solutions:</strong> Crafting optimized database
                structures, ensuring data integrity, security, and swift access.
              </li>
              <li>
                <strong>Server Management:</strong> Efficiently setting up,
                managing, and maintaining servers to ensure peak performance.
              </li>
              <li>
                <strong>Integration Services:</strong> Seamlessly integrating
                with third-party systems, services, and platforms.
              </li>
            </ul>
          </p>
        </Col>
      </Row>

      {/* Web Optimization & Maintenance */}
      <Row className="mb-5 service-detail">

        <Col md={6}>
          <p>
            The digital landscape is ever-evolving. At Web Tek Innovations, we
            ensure that your online presence remains robust, optimized, and
            relevant.
          </p>
          <p>
            Our Services:
            <ul>
              <li>
                <strong>Performance Audits:</strong> Conducting comprehensive
                assessments to boost website speed and responsiveness.
              </li>
              <li>
                <strong>Regular Updates:</strong> Periodic system updates to
                ensure compatibility with the latest technologies.
              </li>
              <li>
                <strong>Security Enhancement:</strong> Implementing the latest
                security patches to protect your site from vulnerabilities.
              </li>
              <li>
                <strong>SEO Best Practices:</strong> Optimizing websites for
                search engine visibility and ranking.
              </li>
            </ul>
          </p>
        </Col>
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaWrench size={50} className="mb-3" />
          <h3>Web Optimization & Maintenance</h3>
        </Col>
      </Row>

      {/* Software Consultancy */}
      <Row className="mb-5 service-detail">
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaLightbulb size={50} className="mb-3" />
          <h3>Software Consultancy</h3>
        </Col>
        <Col md={6}>
          <p>
            At Web Tek Innovations, we recognize that every business has unique
            needs and specific challenges. Our consultative approach hinges on a
            deep understanding of these requirements, proposing tech solutions
            that align with your goals and vision.
          </p>
          <p>
            Our Services:
            <ul>
              <li>
                <strong>Technical Advisory:</strong> Assisting in choosing the
                right technologies and platforms for your business.
              </li>
              <li>
                <strong>Digital Strategy:</strong> Defining strategies to
                digitize and optimize business operations.
              </li>
              <li>
                <strong>Analysis & Design:</strong> Crafting tailored solutions
                based on a detailed analysis of your needs.
              </li>
              <li>
                <strong>Process Optimization:</strong> Enhancing and modernizing
                business processes using best tech practices.
              </li>
            </ul>
          </p>
        </Col>
      </Row>

      {/* AI & Machine Learning Integration */}
      <Row className="mb-5 service-detail">
        <Col md={6}>
          <p>
            The digital age is being reshaped by AI and Machine Learning. At Web
            Tek Innovations, we harness these technologies to create intelligent
            systems that enhance user experiences, optimize operations, and
            provide actionable insights.
          </p>
          <p>
            Our Services:
            <ul>
              <li>
                <strong>Personalization:</strong> Creating tailored user
                experiences by leveraging user data and behavioral patterns.
              </li>
              <li>
                <strong>Data Analysis:</strong> Extracting meaningful insights
                from complex data sets.
              </li>
              <li>
                <strong>Forecasting:</strong> Predicting future trends based on
                historical data.
              </li>
              <li>
                <strong>Automation:</strong> Implementing intelligent systems to
                automate repetitive tasks, reducing human error.
              </li>
            </ul>
          </p>
        </Col>
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaBrain size={50} className="mb-3" />
          <h3>AI & Machine Learning Integration</h3>
        </Col>
      </Row>

      {/* Cloud Solutions */}
      <Row className="mb-5 service-detail">
        <Col md={6} className="col-hover-effect text-center my-4">
          <FaCloud size={50} className="mb-3" />
          <h3>Cloud Solutions</h3>
        </Col>
        <Col md={6}>
          <p>
            The scalability, flexibility, and efficiency of cloud solutions are
            unmatched. Web Tek Innovations offers comprehensive cloud services,
            ensuring that businesses can harness the full power of the cloud,
            tailored to their unique needs.
          </p>
          <p>
            Our Services:
            <ul>
              <li>
                <strong>Cloud Migration:</strong> Transitioning businesses to
                cloud platforms seamlessly.
              </li>
              <li>
                <strong>Infrastructure Management:</strong> Setting up,
                monitoring, and managing cloud infrastructures for optimal
                performance.
              </li>
              <li>
                <strong>Security & Compliance:</strong> Ensuring data integrity
                and security in the cloud environment.
              </li>
              <li>
                <strong>Cost Optimization:</strong> Implementing strategies to
                utilize cloud resources efficiently and cost-effectively.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
      <Row>
        <img src={Img13} alt="Web Tek Innovations Laptop" />
      </Row>
    </Container>
  );
}
