import { Form, Button, Container, Row, Col } from "react-bootstrap";


export function ContactForm() {
  return (
    <Container>
      <Row className="mt-5">
        <Col className="text-center">
          <h2>Ready to get started?</h2>
          <p>Contact us today and let's bring your vision to life.</p>
        </Col>
      </Row>
      <Row className="justify-content-center bg-dark rounded py-5">
        <h1 className="text-center text-white">Contact Us</h1>
        <Col md={6}>
          <Form action="https://formspree.io/f/xpzgdzyp" method="POST">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">Name</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-white">Email</Form.Label>
              <Form.Control type="email" name="_replyto" required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-white">Message</Form.Label>
              <Form.Control as="textarea" name="message" rows={3} required />
            </Form.Group>

            <Button variant="light" type="submit">
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
