import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaServer,
  FaWrench,
  FaLightbulb,
  FaBrain,
  FaCloud,
} from "react-icons/fa";

import "./styles/MainOurServicesStyle.css";

export function OurServices() {
  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">Our Services</h1>

      <p className="text-center mb-4">
        At Web Tek Innovations, we pride ourselves on being at the forefront of
        web-based software solutions. Here's a snapshot of the primary services
        we offer:
      </p>

      <Row className="mb-4">
        <Col md={6} className="col-hover-effect text-center">
          <FaLaptopCode size={40} className="mb-3" />
          <h3>Custom Web Development</h3>
          <p>
            Crafting responsive websites that look and function flawlessly on
            any device. From e-commerce solutions for businesses of all sizes to
            integrations with systems like WordPress, Laravel, React and more.
          </p>
        </Col>
        <Col md={6} className="col-hover-effect text-center">
          <FaMobileAlt size={40} className="mb-3" />
          <h3>Progressive Web Apps (PWA)</h3>
          <p>
            Delivering app-like experiences straight from a web browser with
            high performance and SEO optimization.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} className="col-hover-effect text-center">
          <FaServer size={40} className="mb-3" />
          <h3>Backend Development</h3>
          <p>
            Building secure and efficient APIs using technologies like Node.js,
            among others. Integrations with databases, third-party systems, and beyond.
          </p>
        </Col>
        <Col md={6} className="col-hover-effect text-center">
          <FaWrench size={40} className="mb-3" />
          <h3>Web Optimization & Maintenance</h3>
          <p>
            Conducting web performance audits and speed enhancements. Regular
            maintenance and updates to ensure website security and optimal
            performance.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} className="col-hover-effect text-center">
          <FaLightbulb size={40} className="mb-3" />
          <h3>Software Consultancy</h3>
          <p>
            Offering insights and strategies for software solutions. Evaluating
            technologies and providing recommendations based on business needs.
          </p>
        </Col>
        <Col md={6} className="col-hover-effect text-center">
          <FaBrain size={40} className="mb-3" />
          <h3>AI & Machine Learning Integration</h3>
          <p>
            Deploying AI solutions to elevate user experiences. Machine Learning
            models for data analysis and forecasting.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={12} className="col-hover-effect text-center">
          <FaCloud size={40} className="mb-3" />
          <h3>Cloud Solutions</h3>
          <p>
            Setting up and managing cloud infrastructures with providers like
            AWS, Google Cloud, and Azure. Cloud migration strategies and
            execution.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
