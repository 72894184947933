import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

//importaciones de soporte multilingue
/*import './i18n';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from './locales/es/translation.json';
import global_en from './locales/en/translation.json';*/
//fin importaciones de soporte multilingue

//importaciones de componentes
import { Header } from './components/Header';
import { Footer } from './components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
            <Header />
            <Footer />
    </>
);