import React from "react";
import { Container, Row, Col} from "react-bootstrap";

//Importacion de Imagenes
import Img1 from "../img/1.png";
import Img10 from "../img/10.JPG";
//Fin de Importacion de Imagenes

//Importacion de Componentes
import { AboutUs } from "../components/MainAboutUs";
import { OurServices } from "../components/MainOurServices";
//Fin de Importacion de Componentes

export function Main() {

  return (
    <>
    <div className="d-flex flex-column align-items-center my-5 mx-5">
        <img src={Img1} alt="Imagen principal de web tek innovations" className="img-fluid w-100 mb-4" />

        <Container>
            <Row  className="w-100 mx-auto justify-content-center">
                <Col md={6}>
                    <AboutUs></AboutUs>
                </Col>
                <Col md={6} className="my-5 py-5">
                    <img src={Img10} alt="Web Tek Innovations" className="img-fluid w-100 mb-4"/>
                </Col>
            </Row>

            <Row className="mb-5">
                <OurServices></OurServices>
            </Row>
        </Container>
    </div>
</>


  );
}
