import React from "react";
import { ContactForm } from "../components/ContactUsForm";
import Img4 from "../img/4.png";

export function ContactUs() {
    return (
        <div className="d-flex flex-column align-items-center my-5 mx-5">
            <img src={Img4} alt="Imagen principal de web tek innovations" className="img-fluid w-100 mb-4" />
            <ContactForm></ContactForm>
        </div>
    );
    }