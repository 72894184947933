import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaBuilding,
  FaLightbulb,
  FaCode,
  FaHandshake,
  FaForward,
  FaUserFriends,
  FaSpaceShuttle
} from "react-icons/fa";

import "./styles/AboutUsMainStyle.css";

import { useTranslation } from "react-i18next";

export function MainAboutUs() {
  const { t } = useTranslation();
  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">{t("About Us")}</h1>

      <Row className="mb-4">
        <Col md={12} className="text-center col-hover-effect">
          <FaBuilding size={50} className="mb-3" />
          <p>
            Welcome to Web Tek Innovations.
            Established in 2023, our journey began from a passion to redefine
            digital horizons and offer unmatched software services. Our founder,
            driven by the ambition to bridge the technological gaps faced by
            businesses, set out with a vision: to create a company that doesn't
            just survive, but thrives and leads. Our primary focus at Web Tek
            Innovations has always been on web software solutions, but our
            ambitions don't stop there. Inspired by the dynamic possibilities
            the digital world offers, we've expanded our horizons to bring
            businesses one step closer to the future.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} className="text-center col-hover-effect">
          <FaLightbulb size={50} className="mb-3" />
          <h3>Our Genesis</h3>
          <p>
            The inception of Web Tek Innovations wasn't just a business move; it
            was a commitment. A commitment to excellence, innovation, and the
            transformative power of technology. The driving force behind our
            foundation was twofold: to provide unparalleled software services to
            other businesses and to carve a niche for ourselves with our
            proprietary software solutions. While we have recently embarked on
            this journey, we are optimistic about the milestones we're set to
            achieve.
          </p>
        </Col>
        <Col md={6} className="text-center col-hover-effect">
          <FaSpaceShuttle size={50} className="mb-3" />
          <h3>Our Mission</h3>
          <p>
            To us, success isn't just about profitability – although that
            remains a significant metric. At our core, we aim to revolutionize
            the way businesses perceive technology. We believe in forging
            partnerships, not just client relationships. With every project we
            undertake, our goal is to empower businesses, boost their
            operational efficiency, and help them tap into unexplored markets.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} className="text-center col-hover-effect">
          <FaCode size={50} className="mb-3" />
          <h3>Our Services</h3>
          <p>
            At the heart of our offerings lies custom software solutions, with a
            keen focus on web software. We understand the digital landscape's
            nuances and craft solutions that are not just efficient but also
            scalable, ensuring our partners stay ahead of the curve.
          </p>
        </Col>
        <Col md={6} className="text-center col-hover-effect">
          <FaHandshake size={50} className="mb-3" />
          <h3>Our Values</h3>
          <p>
            <b>Innovation</b> We don't follow the trends; we aim to set them. Every challenge is
            an opportunity for us to innovate and create.
          </p>
          <p>
            <b>Excellence</b> Our commitment to excellence is unwavering. It's not just about
            completing a project; it's about exceeding expectations.
          </p>
          <p>
            <b>Integrity</b> Trust is the foundation of every relationship we build. We believe
            in transparency, honesty, and ethical practices in all our dealings.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} className="text-center col-hover-effect">
          <FaUserFriends size={50} className="mb-3" />
          <h3>Our Team</h3>
          <p>
            At present, the heart and soul of Web Tek Innovations is our CEO. 
            While we might be a small team now, we are agile,
            dedicated, and driven by a passion for what we do.
          </p>
        </Col>
        <Col md={6} className="text-center col-hover-effect">
          <FaForward size={50} className="mb-3" />
          <h3>Looking Ahead</h3>
          <p>
            While our accolades column is yet to be populated, our aspirations
            are sky-high. We foresee a future replete with collaborations,
            innovations, and numerous success stories. Every day, with every
            project, we strive to be better, pushing the boundaries of what's
            possible. Join us on our journey as we aim to reshape the
            technological landscape.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
