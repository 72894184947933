import { useTranslation } from "react-i18next";
import React from "react";
import { ProjectsMain } from "../components/ProjectsMain";

export function Projects() {

  return (
    <ProjectsMain></ProjectsMain>
  );

}