import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  FaGithub,
  FaLinkedin,
  FaChessKing
} from "react-icons/fa";

export function Footer() {
  return (
    <footer style={{ backgroundColor: "#F5F5F5" }}>
      <Container className="py-5">
        <Row>
          <Col md={4}>
            <h5 className="text-dark mb-3"><strong>Web Tek Innovations</strong></h5>
            <p><strong>Crafting Digital Briliance For Your Business</strong></p>
            <p className="text-dark">
              Innovation and technology for your business.
            </p>
          </Col>

          <Col md={4}>
            <h5 className="text-dark mb-3">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <FaGithub></FaGithub>
                <a href="https://github.com/Web-Tek-Innovations" className="text-dark">
                  Our GitHub
                </a>
              </li>
              <li>
                <FaChessKing></FaChessKing>
                <a href="https://danielvalcarce.com/" className="text-dark">
                  CEO Website
                </a>
              </li>
              <li>
                <FaLinkedin></FaLinkedin>
                <a href="#" className="text-dark">
                  Linkedin
                </a>
              </li>
            </ul>
          </Col>

          <Col md={4}>
            <h5 className="text-dark mb-3">Contact Us</h5>
            <p className="text-dark">
              34 N Franklin Ave Ste 687, Pinedale, WY 82941, USA
              <br />
              <strong>Email:</strong>contacto@webtekinnovations.com
            </p>
          </Col>
        </Row>

        <Row className="border-top pt-3">
          <Col md={6} className="text-dark text-left">
            © 2023 Web Tek Innovations LLC. All rights reserved.
          </Col>
          <Col md={6} className="text-dark text-right">
            Designed with ❤️ by Web Tek Innovations
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
